export const hero = [
  {
    id: 1,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/13d925b5-07ea-4477-ac2c-ac79d30ba000-1.jpg',
    category: "CULTURA",
    title: "Teatro Vera en marcha: imágenes de la obra encaminada",
    time: "19 Sep - 2024",
    desc: [
      {
        para1: "19/09/2024 - Este miércoles se realizó una conferencia y posterior visita al Teatro Vera para medios de comunicación, con la idea de mostrar los avances de las obras del tradicional coliseo correntino.",
      },
      {
        para2: "La mesa de presentación estuvo conformada por la presidente del Instituto de Cultura de Corrientes, Beatriz Kunín; la directora general del Teatro Oficial Juan de Vera, Lourdes Sánchez, acompañadas por Eduardo Sívori, director de Artes Escénicas, Música y Artes Audiovisuales del Instituto de Cultura de Corrientes; Francisco Benítez y la arquitecta Marta Vízcaino.",
      },
      {
        para3: "En la oportunidad, la presidente del Instituto de Cultura expresó su deseo de que la obra finalizada se pueda inaugurar para la velada especial del 9 de Julio del 2025.",
      },
    ],
    details: [
      {
        title: "",
      },
      { 
        para1: "Durante la visita, se mostraron imágenes que reflejan el avance significativo en la restauración y mejoras del teatro, evidenciando el compromiso de las autoridades con la cultura y el patrimonio de Corrientes." 
      },
      {
        quote: "Esperamos que el Teatro Vera vuelva a abrir sus puertas para ofrecer a la comunidad un espacio renovado y listo para albergar grandes espectáculos.",
      },
      {
        para2: "La arquitecta Marta Vízcaino destacó los desafíos técnicos superados y la importancia de preservar la esencia histórica del edificio mientras se incorporan mejoras modernas.",
      },
      {
        para3: "",
      },
    ],
  },
{
  id: 2,
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/02-foto-20-pagina-12.jpg',
  category: "JUDICIALES",
  title: "Pozzer Penzo y una avanzada trunca en contra de empleados judiciales",
  time: "19 Sep - 2024",
  desc: [
    {
      para1: "19/09/2024 - En las últimas horas, trascendió un par de definiciones de alto voltaje emanadas de la Cámara Federal de Apelaciones que impactó de lleno en la figura de la jueza Federal de Goya, Cristina Pozzer Penzo. Fueron dos medidas a favor de dos empleados judiciales que habían sido acusados por la magistrada de 12 hechos ilícitos, al parecer de la Jueza, que fueron desestimados por el juez Juan Carlos Vallejos.",
    },
    {
      para2: "El titular del Juzgado Federal N° 1 de Corrientes sobreseyó -en agosto- a un secretario y a un ordenanza tras ser denunciados por presunta alteración de la cadena de custodia de pruebas en una causa o de coaccionar con violencia de género, entre otros.",
    },
    {
      para3: "De esta forma, Pozzer Penzo realizó una presentación de un recurso para reabrir la investigación, lo que fue denegado por los jueces Luis González, Mirta Sotelo de Andreau y Selva Spessot.",
    },
  ],
  details: [
    {
      title: "SOBRESEÍDOS",
    },
    { 
      para1: "A la vez, los integrantes de la Cámara también rechazaron un pedido de la Jueza de Goya para recusar a su colega Vallejos. Una estrategia para quitar del camino judicial a quien había fallado a favor de los apuntados por ella. Consideraron que la magistrada no tiene legitimación para apelar en la causa, por ser denunciante y no querellante.",
    },
    {
      quote: "",
    },
    {
      para2: "La denuncia de Pozzer Penzo había tenido como blancos al secretario penal, Héctor Daniel Montiel y el auxiliar ordenanza Enzo Walter Cáceres (delegado sindical), ambos del Juzgado Federal de Goya.",
    },
    {
      para3: "La Jueza los acusó de cometer 12 hechos relacionados con el presunto intento de romper la cadena de custodia de sobres, así como la no reserva de datos de denunciantes, víctimas y menores; a la vez los apuntó por supuestas coacciones con violencia de género y una medida relacionada con la mesa de entradas, debido a desperfectos en un aire acondicionado, entre otros sucesos.",
    },
  ],
},
{
  id: 3,
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/mario-del-transito-cocomarola-el-1.jpg',
  category: "CULTURA",
  title: "Día Nacional del Chamamé: cómo nació la celebración",
  time: "19 Sep - 2024",
  desc: [
    {
      para1: "19/09/2024 - Cada 19 de septiembre se festeja en toda la Argentina el Día Nacional del Chamamé, en homenaje al músico argentino Mario del Tránsito Cocomarola, que fue uno de los pioneros del género.",
    },
    {
      para2: "En un principio, la conmemoración solo se llevaba a cabo en Corrientes, hasta 2009 que se transformó en nacional, debido a la promulgación de la Ley Nacional N° 26.558. En específico, la fecha se eligió para recordar la muerte del folclorista Cocomarola, quien falleció el 19 de septiembre de 1974 a sus 56 años, a causa de una complicación durante una operación de vesícula.",
    },
    {
      para3: "En la provincia de Corrientes se estableció el Día del Chamamé con la Ley Provincial N° 3278. En esa provincia también tiene lugar cada enero la Fiesta del Chamamé en el Anfiteatro Cocomarola, otro homenaje al músico.",
    },
  ],
  details: [
    {
      title: "El chamamé declarado Patrimonio Histórico de la Humanidad",
    },
    { 
      para1: "En 2020, la UNESCO (Organización de las Naciones Unidas para la Educación, la Ciencia y la Cultura) declaró al chamamé como Patrimonio Histórico de la Humanidad por su aporte trascendental a la cultura de todo el continente.",
    },
    {
      quote: "",
    },
    {
      title: "Quién fue Mario del Tránsito Cocomarola",
    },
    {
      para2: "Mario del Tránsito Cocomarola, conocido también como “El taita del chamamé”, nació el 15 de agosto de 1918 en El Albardón, un paraje de San Cosme, en Corrientes. Siguiendo los pasos de su padre, Felipe Cocomarola—quien era un inmigrante italiano—se involucró desde muy chico en la música con un acordeón de ocho bajos y 21 botones. Luego, se pasó al bandoneón.",
    },
    {
      para3: "Pasó por la Orquesta Folklórica de Corrientes, dirigida por Ricardo Suárez, y también tocó durante tres años en LT7 Radio Corrientes. En 1940, se instaló en Buenos Aires para enfocarse en su carrera como artista.",
    },
    {
      para3: "Su primer disco lo grabó en 1943 con el sello Odeón. Desde entonces, dio inicio a una extensa y exitosa trayectoria como solista que recién llegó a su final el día de su muerte.",
    },
    {
      para3: "En la mañana del 18 de septiembre de 1974, fue a un sanatorio de Corrientes para afrontar una operación de vesícula. Sin embargo, por la gravedad de su cuadro, fue trasladado a Buenos Aires, donde murió a la 1:30 de la madrugada del 19 de septiembre, a causa de una hemorragia estomacal.",
    },
    {
      para3: "Desde entonces, es recordado como el máximo exponente del chamamé. Tras su partida, dejó como legado un inmenso e imprescindible repertorio de más de 200 piezas musicales. Entre ellas, 'Kilómetro 11' es considerada un himno litoraleño por excelencia y debe su nombre a la pinchadura de una rueda. Cocomarola, junto a Félix Vallejo, la compuso mientras esperaba que cambiaran un neumático en el kilómetro 11 de la ruta provincial N° 12 de Corrientes, para luego continuar viaje a una presentación.",
    },
  ],
},
{
  id: 4,
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2023/10/Fiesta-del-Chamame-8-4.jpg',
  category: "CULTURA",
  title: "Fiesta Nacional del Chamamé: ¿habrá cambios en la grilla?",
  time: "19 Sep - 2024",
  desc: [
    {
      para1: "19/09/2024 - La nueva presidenta del Instituto de Cultura de Corrientes, Beatriz Kunin, dio detalles de la 34º Fiesta Nacional del Chamamé, que a su vez será la 20° del Mercosur y la 4° Mundial, que se realizaría desde el 17 de enero de 2025, en el anfiteatro Cocomarola, tal lo anunciado oficialmente.",
    },
    {
      para2: "Sin embargo, esta nueva edición que lleva por lema 'Chamamé, avío del alma', según lo dejó entrever Kunin, podría tener cambios en la grilla que está en proceso de conformación.",
    },
    {
      para3: "",
    },
  ],
  details: [
    {
      title: "¿Cambios en la Grilla?",
    },
    {
      para1: "“Respecto en la fiesta en sí misma... están las fechas, se sabe que comienza el 17 de enero, pero la grilla no está definida, en eso estamos trabajando lentamente”, afirmó.",
    },
    {
      quote: "Confirmó además que 'se va a transmitir por la Televisión Pública todas las noches', lo que permitirá llegar a todo el país.",
    },
    {
      para2: "Aseguró Kunin que se evalúan algunos cambios, y sostuvo que 'el anfiteatro es un lugar muy especial donde tienen que subir los consagrados... hay que aprovechar más a quienes (por los artistas) vienen desde muy lejos y que estén más tiempo sobre el escenario. Tenemos figuras sumamente importantes que con seguridad van a querer estar más tiempo y también el público va a querer' que tengan más tiempo de actuación.",
    },
    {
      para3: "Considera en ese sentido, que en paralelo podrían 'existir otras fiestas, peñas, otras localidades, que también en eso podamos ser más federales, que podamos llegar a otras localidades y que no tengamos tantos grupos sobre el escenario'.",
    },
    {
      para3: "“Tenemos que tener en cuenta que hay localidades que pueden funcionar como cabeceras y aquella gente que por alguna razón no se puede desplazar hasta la Capital tenga también la posibilidad de compartirla”, agregó.",
    },
    {
      para3: "“Hay unos cuantos detalles a cerrar, pero existe la idea de un pequeño cambio”, sostuvo para cerrar la charla con Canal 5TV.",
    },
    {
      para3: "La titular del Instituto de Cultura adelantó que el próximo sábado 28, se la presentará en Buenos Aires, en el Parque Centenario, tras lo cual se hará una gira de difusión por las provincias vecinas, el Interior de Corrientes y el Paraguay.",
    },
  ],
},
]
export const world = [
  {
    id: 5,
    cover: "https://www.lanacion.com.ar/resizer/v2/la-devaluacion-trajo-fuertes-costos-que-el-equipo-NTIFKCFGXRCGPNQBAVZKGCEZ3Y.JPG?auth=98785e2b145e8eef125b3919f7e51fb1a3c1486a797cf16f150738ed9f1b12cf&width=880&height=586&quality=70&smart=false",
    category: "ECONOMIA",
    title: "Repartir hasta lo ajeno para pagar la elevada factura de la devaluación oficial",
    authorName: "Salman",
    time: "5 Jun - 2022",
    desc: [
      {
        para1: "You audience. Least, the recently his repeat the this avarice for the have and was on would before the concise bedding were hall politely name be regretting have of even five of it the his are there again. Word seven designer far lady problem will have work with you to fully understand your business to achieve.",
      },
      {
        para2: "We work with clients big and small across a range of sectors and we utilize all forms of media to get your name out there in a way that’s right for you. We believe that analysis of your company and your customers is key in responding effectively to your promotional needs and we will work with you.",
      },
      {
        para3: "We have a number of different teams within our agency that specialise in different areas of business so you can be sure that you won’t receive a generic service and although we can’t boast years and years of service we can ensure you that is a good thing in this industry.",
      },
    ],
    details: [
      {
        title: "Starting a new company is easy",
      },
      { para1: "Our teams are up to date with the latest technologies, media trends and are keen to prove themselves in this industry and that’s what you want from an advertising agency, not someone who is relying on the same way of doing things that worked 10 years, 5 years or even a year ago." },
      {
        quote: "Scarfs, still not this no with explains it me and option on the any options roasted when I and state can that an don't subjective of has his take on and in from royal everything took raising our have behind success you the mechanic.",
      },
      {
        para2: "And, higher by agency; In from their in and we spirit, through merely small him sounded a all now, with that put gift white highly geared that was left back as of or logged important. A over have the large try understanding the believe. Perfected been viewer. Shreds early willingly safely what passion the.",
      },
      {
        para3: "In an ideal world this website wouldn’t exist, a client would acknowledge the importance of having web copy before the design starts. Needless to say it’s very important, content is king and people are beginning to understand that. However, back over in reality some project schedules and budgets don’t allow for web copy to be written before the design phase, this is sad but true.",
      },
    ],
  },
  {
    id: 6,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "Renewablee91 ener553gy dead industry waits for Policya ",
    authorName: "Salmana",
    time: "5 Jun - 2022",
    desct: "If rather, him the would treble-range wild any long of policy waved preparations disguised first initial that commitment had my in for spirits gradual solitary I for his absolutely with it and I to unable follow the to as every it the control and a of thinks the what's help days, with and paint, series at window and could we've offer, hard with king either written dry be are of princesses. We’re here to help, we’ve written examples of web copy for over 40 industries for you to use at concept phase of your projects to bring a little life and realism to your designs and help you think about who and what you are designing for. We want clients and designers alike to think about their design and how it will work with the web copy, we want you think about how numbers, symbols and bullet points will look. Sure, it's easier to just run down the forms and fill the fields with garbage in order to plow through them quickly. But that's not real. That's not what your customers are going to do. Is it really smart to take a shortcut when customers are forced to take the long road? When you just enter fake copy in rapid-fire fashion, you don't know what it really feels like to fill out that form. Do as your customers do and you'll understand them better. When you understand them better, and feel what they feel, you'll build a better interface.",
  },
  {
    id: 7,
    cover: "../images/world/world3.jpg",
    category: "",
    title: "Mount Etna erupts fashion in nice12 looking pictures ",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
  {
    id: 8,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "China asks Canada to add their full plan ",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
  {
    id: 9,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "Serena secures comeback win against Ivanovic",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
]
export const popular = [
  {
    id: 10,
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/gendarmeria-19-9.jpg',
  category: "SOCIEDAD",
  title: "Secuestraron mercadería valuada en más de 28 millones de pesos en el interior",
  date: "19 Sep - 2024",
  desc: [
    {
      para1: "19/09/2024 - Efectivos de la Gendarmería Nacional informaron el decomiso de encomiendas que contenían mercadería de contrabando.",
    },
    {
      para2: "Efectivos del Escuadrón 7 de Paso de los Libres Cabo Misael Pereyra abrieron un total de 13 encomiendas que habían sido interdictadas en el control de la Ruta Nacional 14, a la altura de la localidad de Tapebicuá y peaje Piedritas.",
    },
    {
      para3: "Asimismo, integrantes de la Sección Itá Ibaté, dependiente del Escuadrón 47 Ituzaingó, hicieron lo propio mediante la apertura de 15 encomiendas.",
    },
  ],
  details: [
    {
      title: "",
    },
    {
      para1: "En todos los casos, la paquetería era enviada desde distintos puntos de la provincia de Misiones, teniendo como destino final la provincia de Buenos Aires.",
    },
    {
      quote: "",
    },
    {
      para2: "Había mercadería extranjera que carecía del aval aduanero, tratándose de 12 neumáticos, 261 pares de calzado, 180 prendas de vestir, 710 paquetes de tabaco, 50 dispositivos Android para TV, 310 cigarrillos electrónicos, 33 teléfonos celulares y 60 ampollas de vitaminas para ganado.",
    },
    {
      para3: "Informados de los resultados, los Juzgados Federales de Corrientes y de Paso de los Libres dispusieron el secuestro de los elementos en infracción a la Ley de Código Aduanero, con un avalúo en conjunto que asciende a 28.236.920 pesos.",
    },
  ],
},
  {
    id: 11, // ID ASIGNADO
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/descarga-15.jpeg', // URL DE LA IMAGEN
  category: "POLICIALES", // CATEGORÍA
  title: "Empedrado: hallaron un cuerpo en las aguas del río Paraná", // TÍTULO DE LA NOTICIA
  date: "19 Sep - 2024", // FECHA ACTUAL DEL DÍA DE HOY
  desc: [  // TODO EL CONTENIDO DE LA NOTICIA
    {
      para1: "19/09/2024 - Ayer, la Policía halló el cuerpo sin vida de una persona en las aguas del río Paraná.",
    },
    {
      para2: "Por el episodio, efectivos policiales de la Comisaría de Distrito de Empedrado, junto a Prefectura, acudieron a la zona ubicada a unos 400 metros (cardinal sur) del puerto de Empedrado.",
    },
    {
      para3: "Según fuentes policiales, una persona habría observado al hombre flotando, que hasta el momento no pudo ser identificado, y que tampoco cuenta con pedido activo de localización con las características de esta persona.",
    },
  ],
  details: [
    {
      title: "Características y procedimiento", // SUBTÍTULO OPCIONAL
    },
    {
      para1: "Por este motivo, las autoridades judiciales dispusieron que el cuerpo sea trasladado hasta la morgue local, a fin de que se realice la correspondiente autopsia médico legal, desconociéndose hasta el momento su identidad. Sus características: 1.70 metros, contextura robusta, cabello corto de color oscuro con canas, remera de mangas cortas de color verde, con una camisa de mangas largas de color negra con detalles rojos, pantalón largo tipo jeans de color oscuro y zapatos de vestir marrones.",
    },
    {
      quote: "La Comisaría de Distrito de Empedrado solicitó la valiosa colaboración de la ciudadanía para aportar datos que ayuden a identificar correctamente a esta persona.",
    },
    {
      para2: "Las investigaciones continúan en curso para esclarecer la situación y la identidad de la víctima.",
    },
    {
      para3: "",
    },
  ],
},
  {
    id: 12, // ID ASIGNADO
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/imagen-90963.jpg', // URL DE LA IMAGEN
    category: "SALUD", // CATEGORÍA
    title: "Salud en Tu Casa: agentes se acercarán a vecinos de Capital y del Interior", // TÍTULO DE LA NOTICIA
    date: "19 Sep - 2024", // FECHA ACTUAL DEL DÍA DE HOY
    desc: [  // TODO EL CONTENIDO DE LA NOTICIA
      {
        para1: "19/09/2024 - El Ministerio de Salud Pública avanza con el cronograma del programa Salud en Tu Casa, que la próxima semana (desde el lunes 23 hasta el viernes 27) recorrerá de 8 a 12 el barrio Doctor Montaña de Capital y las localidades de Santa Ana, Tabay y Cecilio Echeverría.",
      },
      {
        para2: "Además, en Colonia Carlos Pellegrini estarán por la mañana y también de 15 a 18 al igual que en Colonia Liebig, Guaviraví y Yahapé.",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "Excelencia del programa", // SUBTÍTULO OPCIONAL
      },
      {
        para1: "Se recuerda que el programa es una estrategia de Atención Primaria de la Salud (APS), diseñada por el ministro Ricardo Cardozo. Consiste en que agentes sanitarios, promotores de salud y obstetras visiten los distintos barrios para identificar pacientes con enfermedades como Diabetes, Hipertensión, diversos tipos de Cáncer y embarazos, e integrarlos al sistema sanitario para su tratamiento y seguimiento.",
      },
      {
        quote: "",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
  },
{
  id: 13, // ID ASIGNADO
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/abfb994f954499f86849e6f0245f349a.jpg', // URL DE LA IMAGEN
  category: "SEGURIDAD", // CATEGORÍA
  title: "Seguridad Vecinal: se hacen estrategias de prevención en las salidas de los colegios", // TÍTULO DE LA NOTICIA
  date: "19 Sep - 2024", // FECHA ACTUAL DEL DÍA DE HOY
  desc: [  // TODO EL CONTENIDO DE LA NOTICIA
    {
      para1: "19/09/2024 - La Subsecretaría de Seguridad Ciudadana del Ministerio de Seguridad lideró una nueva reunión con vecinos del barrio Berón de Astrada.",
    },
    {
      para2: "El subsecretario de Seguridad Ciudadana, Oscar Martínez, encabezó el encuentro, acompañado por el comisario mayor Cano, el Jefe de la Comisaría Tercera, comisario inspector Leonardo Antonio Benítez, y el delegado barrial designado por la Municipalidad de Corrientes.",
    },
    {
      para3: "Durante el encuentro, los vecinos fueron informados de las estrategias de seguridad implementadas en la zona, como los operativos de prevención en las salidas de los colegios y el constante patrullaje de vehículos policiales en la zona.",
    },
  ],
  details: [
    {
      title: "Aplicación y participación vecinal", // SUBTÍTULO OPCIONAL
    },
    {
      para1: "Se destacó la importancia de la aplicación «Vecinos Seguros», que facilita una respuesta rápida del 911 ante situaciones de emergencia, fortaleciendo la capacidad de reacción ante posibles incidentes.",
    },
    {
      quote: "El subsecretario Martínez enfatizó la importancia de la participación activa de los vecinos en la prevención del delito y en la construcción de una comunidad más segura.",
    },
    {
      para2: "Se comprometió a seguir trabajando en conjunto con la comunidad y las fuerzas de seguridad para fortalecer las medidas de prevención y brindar mayor tranquilidad a los habitantes del barrio Berón de Astrada.",
    },
    {
      para3: "La reunión culminó con un intercambio fructífero de ideas y propuestas, dejando en claro el compromiso de todos los presentes por trabajar juntos en pos de una mayor seguridad vecinal.",
    },
  ],
},
{
  id: 14, // ID ASIGNADO
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/leds.jpg', // URL DE LA IMAGEN
  category: "OBRAS PÚBLICAS", // CATEGORÍA
  title: "Instalaron nuevas luminarias con tecnología LED en cerca de 120 barrios capitalinos", // TÍTULO DE LA NOTICIA
  date: "19 Sep - 2024", // FECHA ACTUAL DEL DÍA DE HOY
  desc: [  // TODO EL CONTENIDO DE LA NOTICIA
    {
      para1: "19/09/2024 - Con diferentes acciones para lograr una mayor modernización en la ciudad, la Municipalidad de Corrientes avanza con la instalación de luces LED en diferentes barrios capitalinos. Ya son 118 los barrios intervenidos con este sistema de iluminación, con más de 24 mil luminarias instaladas.",
    },
    {
      para2: "Así lo informó el secretario de Infraestructura del municipio, Mathías Cabrera, quien detalló que en el marco de estas obras «estamos terminando con la instalación de luces LED en el barrio San Gerónimo en un 100%, lugar donde estamos interviniendo ya hace un buen tiempo y estamos a pasos de completar también en un 100% la construcción de cordón cuneta».",
    },
    {
      para3: "Cabrera remarcó que este plan de recambio de luminarias «además de traer una mejor iluminación en el barrio, se traduce directamente en seguridad. Pero también es una luminaria que dura más tiempo, que es más económica, que consume mucho menos de energía eléctrica, con lo cual aportamos al cuidado del ambiente también».",
    },
  ],
  details: [
    {
      title: "Proceso y beneficios", // SUBTÍTULO OPCIONAL
    },
    {
      para1: "El secretario de Infraestructura municipal recordó que estas obras se realizan con el Fondo Fiduciario de Garantía para la Obra Pública (Fogop), donde los costos son cubiertos totalmente por la comuna, que recupera en parte la inversión con el aporte en cuotas de los frentistas.",
    },
    {
      quote: "«Es un sistema muy sencillo y beneficioso para los vecinos. Se ponen de acuerdo los que residen en una misma cuadra, y presentan una nota a la Secretaría de Hacienda del municipio. Luego se hace una reunión donde se le pide que presten su conformidad y la comuna se hace cargo de todos los materiales y la instalación y a los vecinos les quedan cómodas cuotas para poder abonar esa iluminación que se ha hecho», explicó Cabrera.",
    },
    {
      para2: "Cabrera también destacó que las luminarias instaladas cuentan con cinco años de garantía, lo que permite recambiarlas automáticamente en caso de fallas para garantizar la continuidad del servicio.",
    },
    {
      para3: "",
    },
  ],
},
{
  id: 15, // ID ASIGNADO
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/DSC06768.jpg', // URL DE LA IMAGEN
  category: "CLIMA", // CATEGORÍA
  title: "Alerta amarilla por tormentas fuertes en Corrientes", // TÍTULO DE LA NOTICIA
  date: "19 Sep - 2024", // FECHA ACTUAL DEL DÍA DE HOY
  desc: [  // TODO EL CONTENIDO DE LA NOTICIA
    {
      para1: "19/09/2024 - El Servicio Meteorológico Nacional (SMN) informó que todo el territorio de la provincia de Corrientes ingresará este jueves en alerta amarilla por tormentas.",
    },
    {
      para2: "El aviso indica que, especialmente durante la tarde y noche, se podrían registrar en todo el suelo correntino “tormentas, algunas de las cuales podrían ser localmente fuertes, acompañadas de intensa actividad eléctrica, abundante caída de agua en cortos períodos, ocasional caída de granizo y, principalmente, ráfagas intensas de hasta 80 km/h”.",
    },
    {
      para3: "Respecto a las lluvias, indicaron que “se estiman valores de precipitación acumulada entre 25 y 40 mm, pudiendo ser superados de forma local”.",
    },
  ],
  details: [
    {
      title: "Pronóstico en la capital", // SUBTÍTULO OPCIONAL
    },
    {
      para1: "En la Capital correntina, el pronóstico indica que el mal tiempo se extendería hasta la tarde del viernes y que las temperaturas oscilarán entre los 19°C y los 27°C.",
    },
    {
      quote: "A partir del sábado, se espera buen tiempo con el termómetro en ascenso, con marcas por encima de los 30°C, que llegarían a los 36°C entre el lunes y el martes.",
    },
    {
      para2: "",
    },
    {
      para3: "",
    },
  ],
},
{
  id: 16, // ID ASIGNADO
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/oso-hormiguero-rescatado-2.jpg', // URL DE LA IMAGEN
  category: "MEDIO AMBIENTE", // CATEGORÍA
  title: "Rescataron a un oso hormiguero que estaba atrapado en un alambrado en Corrientes", // TÍTULO DE LA NOTICIA
  date: "19 Sep - 2024", // FECHA ACTUAL DEL DÍA DE HOY
  desc: [  // TODO EL CONTENIDO DE LA NOTICIA
    {
      para1: "19/09/2024 - Efectivos de la Policía de Corrientes rescataron a un oso hormiguero que fue hallado atrapado frente a un colegio capitalino.",
    },
    {
      para2: "Oficiales de la Comisaría Décimo Octava se encontraban realizando una ronda de prevención cuando lograron divisar al animal en apuros, frente al colegio Eloy Ortega, en calle Cerdeña, entre Milán y Alejandro Dumas.",
    },
    {
      para3: "Tras coordinar con el Centro de Protección y Rescate Aguará, los oficiales confirmaron que el oso hormiguero no presentaba lesiones visibles. Posteriormente, fue liberado en su hábitat natural.",
    },
  ],
  details: [
    {
      title: "Preservación de la fauna", // SUBTÍTULO OPCIONAL
    },
    {
      para1: "El hecho destaca la importancia de preservar la fauna nativa y la labor de las fuerzas de seguridad en su resguardo.",
    },
    {
      quote: "",
    },
    {
      para2: "",
    },
    {
      para3: "",
    },
  ],
},
{
  id: 17, // ID ASIGNADO
  cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/09/03-FOTO-30-ARCHIVO-1.jpg', // URL DE LA IMAGEN
  category: "POLÍTICA", // CATEGORÍA
  title: "Gobernadores llegan a Bella Vista con proyectos y demandas", // TÍTULO DE LA NOTICIA
  date: "19 Sep - 2024", // FECHA ACTUAL DEL DÍA DE HOY
  desc: [  // TODO EL CONTENIDO DE LA NOTICIA
    {
      para1: "19/09/2024 - La ciudad correntina de Bella Vista recibirá este jueves desde el mediodía a seis mandatarios provinciales de la región del Noreste argentino.",
    },
    {
      para2: "La actividad se dará en el marco de un encuentro de gobernadores de provincias litoraleñas y al cierre de la edición estaban confirmadas las presencias de seis: Maximiliano Pullaro (Santa Fe), Leandro Zdero (Chaco), Gildo Insfrán (Formosa), Rogelio Frigerio (Entre Ríos), Maximiliano Passalacqua (Misiones) y el anfitrión, Gustavo Valdés.",
    },
    {
      para3: "En la víspera hubo una reunión entre comisarios para determinar cómo sería el operativo de seguridad. Sería a las 15 sobre la Costanera, y aunque se pronostica lluvias, se confirmó el cónclave en un lugar cerrado.",
    },
  ],
  details: [
    {
      title: "Expectativas y acuerdos", // SUBTÍTULO OPCIONAL
    },
    {
      para1: "«Es importantísimo este encuentro de gobernadores porque es la primera vez que se desarrolla en Bella Vista y la zona por lo cual tenemos grandes expectativas. Es una continuidad de lo que se dio el mes pasado, en la costa santafesina, en Villa Ocampo, frente a nuestro pueblo», puntualizó la intendente bellavistense, Noelia Bazzi.",
    },
    {
      quote: "«Esta parte del país comparte una riqueza enorme y creo que cada provincia tiene algo para aportar. A la vez, también hay una visión estratégica que se viene trabajando desde el Norte Grande», destacó la dirigente radical.",
    },
    {
      para2: "En este sentido, resaltó el perfil del mandatario correntino en potenciar la integración regional, «de trabajar a futuro, de mirar de manera más amplia y saliendo de los límites provinciales».",
    },
    {
      para3: "",
    },
  ],
},
  // HASTA ACA SE VEN DE ENTRADA, LAS DE ABAJO HAY QUE MOVER EL SLIDER PARA VERLAS.
  {
    id: 18,
    category: "POLICIALES",
    title: "Libres: recuperan el dinero robado de un comercio por Ruta 117",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.diarioepoca.com/content/bucket/5/1320445w762h739c.jpg.webp",
    desc: [
      {
        para1: "Efectivos de la Comisaría Primera de Paso de los Libres realizaron ayer un allanamiento y lograron recuperar la totalidad del botín en efectivo que delincuentes robaron de un parador ubicado a la vera de la Ruta Nacional N°117. Hay dos hombres detenidos.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Según la información proporcionada por la Policía, el hecho ocurrió días atrás y durante el fin de semana se demoró a los dos maleantes que habían perpetrado el ilícito, pero faltaba ubicar el lugar donde ocultaban el dinero. Los trabajos consistieron en reunir toda la información posible, que también constó de imágenes de cámaras de seguridad de la zona y con lo que pudieron identificar a los delincuentes." },
      {
        quote: "Por ello, el sábado elementos de la sección Investigaciones de la Comisaría Primera, con orden de allanamiento de la Unidad Fiscal de Investigaciones de Paso de los Libres, requisaron una finca ubicada en la quinta sección Palmar, Chacra 250, donde finalmente lograron dar con la totalidad del dinero en efectivo, alrededor de $450.000 que había sido robado del parador Don Tolo",
      },
      {
        para2: "Tanto los dos detenidos como el dinero fueron puestos a disposición de la Justicia y la investigación continúa para descartar la participación de más personas en el hecho.",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 19,
    category: "TECNOLOGIA",
    title: "Cómo la Inteligencia Artificial está cambiando la forma de buscar vida extraterrestre",
    date: "17. Septiembre 2023",
    comments: 0,
    cover: "https://www.infobae.com/new-resizer/MQrbURODdl9ufluwK5Mch7FjW4c=/992x558/filters:format(webp):quality(85)/cloudfront-us-east-1.images.arcpublishing.com/infobae/ELC3ESJ3AZHKNJETOU2YQT4I7Y.png",
    desc: [
      {
        para1: "Tras el lanzamiento de dos telescopios con el objetivo de encontrar planetas habitables, y con un total de 5.496 exoplanetas confirmados y 9.820 más en espera de este aval, la carrera por caracterizar estos mundos distantes y evaluar su habitabilidad impulsa a la ciencia hacia nuevos límites",
      },
      {
        para2: ".",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "En los últimos dos años y medio, se han enviado al espacio dos telescopios de próxima generación: el Espacial James Webb (JWST) de la NASA y el Observatorio Euclid de la ESA. Antes de que termine la década, se les unirán el Telescopio Espacial Romano Nancy Grace (RST), el Espectrofotómetro para la Historia del Universo, la Época de la Reionización y el Explorador de Hielos (SPHEREx) de la NASA, y el Tránsito y Oscilaciones Planetarias de la ESA (PLATO), entre otros." },
      {
        quote: "Estos observatorios se basarán en óptica e instrumentos avanzados para ayudar en la búsqueda y caracterización de exoplanetas con el objetivo final de encontrar planetas habitables. Junto con las misiones aún operativas, estos observatorios recopilarán volúmenes masivos de datos espectroscópicos de alta resolución.",
      },
      {
        para2: "lasificar esta información requerirá técnicas de aprendizaje automático de vanguardia para buscar indicios de vida y procesos biológicos (también conocidos como biofirmas). En un artículo reciente, un equipo de científicos del Instituto de Teoría Fundamental de la Universidad de Florida (UF-IFL) recomendó que futuros estudios utilicen el aprendizaje automático para buscar anomalías en los espectros, que podrían revelar firmas químicas inusuales y biológicas desconocidas.",
      },
      {
        para3: "Una preimpresión de su artículo se publicó en arXiv y está siendo revisada para su publicación en Astrophysical Journal. Allí los especialistas explicaron, que la “vida” sigue siendo una cuestión abierta para los científicos, y sería ventajoso ampliar el alcance de nuestra búsqueda.",
      },
    ],
  },
  {
    id: 20,
    category: "DEPORTES",
    title: "Boca Unidos logró salir airoso en un partido cambiante y emotivo",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://pxc.cdn.ellitoral.com.ar/litoral/092023/1695000729381.webp?cw=770&ch=440&extw=jpg",
    desc: [
      {
        para1: "El equipo correntino revirtió un resultado adverso para ganarle de local a Crucero del Norte por 2 a 1. Mainero puso en ventaja al Colectivero, pero Gabi Morales, de penal, y Maximiliano Solari sobre el final anotaron para el Aurirrojo.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Boca Unidos golpeó último y se quedó con tres valiosos  puntos al ganarle ayer de local a Crucero del Norte por 2 a 1 para afianzarse en la tercera posición de la zona 4, en un encuentro cambiante y emotivo jugado por la fecha 31 del torneo Federal “A”." },
      {
        quote: "El Aurirrojo regaló el primer tiempo, lapso en el que el Colectivero se puso adelante en el marcador frente a un equipo local que no daba pie con bola. En el complemento, Boca Unidos cambió la imagen, empató a través de un penal, jugada en la que quedó con un hombre más, pero en su afán por ir a buscar enceguecidamente el triunfo se descuidó, cometió errores defensivos en los que su rival le perdonó la vida, hasta que llegó al desequilibrio en el final. El inicio del encuentro encontró a un Crucero del Norte enchufado que salió a jugar el partido como una verdadera final frente a un Boca Unidos que estaba dormido y deambulaba por la cancha sin poder hacer tres pases seguidos. La visita avisó al minuto, con un tiro libre que Nicolás Portillo bajó de cabeza para Alejo Mainero, que fue trabado por Joaquín Livera. ",
      },
      {
        para2: "El gol del Colectivero no  tardó en llegar. Walter Figueroa envió un centro pasado que Gastón Torres devolvió de cabeza hacia el medio. El balón le quedó otra vez a Mainero, que pifió el primer remate, pero inmediatamente se rehizo para enviar el balón a la red.",
      },
      {
        para3: "Ahora el equipo correntino tendrá que ir el próximo domingo a jugar “otra final” en Salta, frente a Juventud Antoniana, que al igual que Crucero del Norte, se juega todas las fechas la permanencia, y luego quedará libre. Pero para todavía falta, por ahora es todo alegría.",
      },
    ],
  },
  {
    id: 21,
    category: "POLITICA",
    title: "Ya se debate en Diputados la modificación del impuesto a las ganancias",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.mdzol.com/u/fotografias/m/2023/9/15/f1280x720-1476969_1608644_5050.jpeg",
    desc: [
      {
        para1: "En una reunión informativa, la Comisión de Presupuesto y Hacienda, presidida por el diputado del Frente de Todos Carlos Heller, la Cámara de Diputados de la Nación comenzó a analizar el proyecto de Ley, enviado el pasado 12 de septiembre, por el cual se deja sin efecto el impuesto a las ganancias que recae sobre los ingresos del trabajo personal ejecutado en relación de dependencia, jubilaciones y pensiones y, por el otro, incorpora en la ley del mencionado gravamen, un régimen cedular dirigido, exclusivamente, a los mayores ingresos derivados de aquellas rentas.",
      },
      {
        para2: "En primer lugar, la subsecretaria de Ingresos Públicos, Claudia Balestrini, defendió el proyecto, y en ese sentido explicó: “Lo que vinimos a hacer ahora es algo superador y tiene que ver con atender a las rentas generadas en relación de dependencia (cuarta categoría)”.",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Además, la funcionaria brindó detalles de la norma: “Las rentas originadas por empleadas y empleados en relación de dependencia van a empezar a tributar bajo un esquema cedular”. “Consiste en establecer un mínimo no imponible de 180 salarios mínimos, vitales y móviles. De superar ese importe el trabajador va a tributar por el excedente”, puntualizó." },
      {
        quote: "En la misma línea que su preopinante, la ministra de Trabajo, Kelly Olmos, aseguró que “el salario no es ganancia”, al tiempo que indicó que “el proyecto establece la eliminación de la cuarta categoría del impuesto a las ganancias y su reemplazo por un impuesto a los altos ingresos”.",
      },
      {
        para2: "“Este proyecto está destinado a una mejor organización del trabajo argentino para mejorar las condiciones de productividad y redistribución que nuestro país necesita”, ponderó la responsable de la cartera de Trabajo.",
      },
      {
        para3: "Por otra parte, el titular de la Administrador Federal de Ingresos Públicos (AFIP), Carlos Castagneto, afirmó que “es un proyecto que deja sin efecto el impuesto a las ganancias que recae sobre los ingresos de trabajo personal, ejecutado en relación de dependencia, jubilaciones y pensiones” y que -añadió-, además, “incorpora un régimen cedular dirigido exclusivamente a los mayores ingresos derivado de aquellas rentas”.",
      },
    ],
  },
  {
    id: 22,
    category: "POLICIALES",
    title: "Arrojaron 11 kilos de marihuana al río y fueron detenidos",
    date: "24. Noviembre 2023",
    comments: 0,
    cover: "https://pxc.cdn.ellitoral.com.ar/litoral/112023/1700825192298.webp?cw=770&ch=440&extw=jpg",
    desc: [
      {
        para1: "El operativo fue realizado por la Policía.",
      },
      {
        para2: "La Policía de Corrientes informó este viernes que secuestró  11 kilos de marihuana y detuvo a dos hombres  en la localidad de Itatí. ",
      },
      {
        para3: "El operativo se realizó el jueves cuando observaron que dos ocupantes de una canoa  arrojaron un paquete al rio Paraná a la altura de una isla de la zona.",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "En ese momento comenzó el procedimiento y se logró detener a dos sujetos, uno de ellos de nacionalidad paraguaya, señaló la fuerza. " },
      {
        quote: "En total, se incautó  11,225 kilos.",
      },
      {
        para2: "La canoa, la droga y los dos involucrados quedaron a disposición de la Justicia. ",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 23,
    category: "ECONOMIA",
    title: "Pagos a jubilados, pensionados, AUH y otras prestaciones del lunes 18",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.corrienteshoy.com/galeria/fotos/2023/09/04/l_1693826424.jpg",
    desc: [
      {
        para1: "La Administración Nacional de la Seguridad Social (ANSES) pagará este entre el lunes 18 de septiembre de 2023 jubilaciones, pensiones, Asignación Universal por Hijo (AUH), Asignación por Embarazo (AUE), Asignaciones Familiares, de Pago Único (Matrimonio, Nacimiento y Adopción).",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Desde este mes las jubilaciones, pensiones y asignaciones se cobrarán con el aumento del 23,29 por ciento por la Ley de Movilidad." },
      {
        quote: "En tanto, todos los jubilados y pensionados que perciben el haber mínimo recibirán la primera cuota del refuerzo de 37 mil pesos (la segunda en octubre y la tercera en noviembre por el mismo monto), por lo que ninguno de ellos cobrará menos de 124.460 pesos.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 24,
    category: "SALUD",
    title: "Más de 4 mil mujeres se hicieron la prueba de parches mamarios",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.corrienteshoy.com/galeria/fotos/2023/09/18/l_1695032518.jpg",
    desc: [
      {
        para1: "Ahora, este dispositivo estará disponible en los Centros de Atención Primaria de la Salud (CAPS) de la capital. Hasta el momento entre los resultados, se mostró un 7.11% de significatividad entre el total de mujeres que se realizaron la prueba que dura 15 minutos.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "El Ministerio de Salud Pública de la Provincia de Corrientes, a cargo de Ricardo Cardozo, lleva adelante la campaña para el diagnóstico precoz del Cáncer de Mama: “Ruta de la Salud Mamaria” a partir de la colocación de PADS o Parches Mamarios. Este nuevo método de diagnóstico precoz impulsado por el gobernador Gustavo Valdés detecta alteraciones relacionadas con este problema de salud, estaba disponible hasta el momento en el interior y algunos centros de salud de capital pero ahora se comenzará a aplicar desde esta semana en los 17 CAPS de la capital provincial." },
      {
        quote: "Los PADS o Parches Mamarios son dispositivos con una innovadora tecnología, que consiste en microsensores que captan las diferencias de temperatura de las mamas, siguiendo principios físicos termodinámicos.",
      },
      {
        para2: "“Desde hace tres meses, a partir de junio se lanzó la campaña, empezamos con las pruebas de los parches mamarios en toda la provincia. Iniciamos en el interior; en algunos centros de capital y ahora en la capital en los CAPS. Tenemos más de 4 mil pruebas de parches realizados a lo largo de toda la provincia y la semana siguiente se avanzará en los CAPS de la capital. Seguramente ahí aumentará la cantidad de pacientes que se colocan”, dijo la coordinadora de la Red Provincial de Cáncer de Mama, Karina Maidana.",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 25,
    category: "CORRIENTES",
    title: "Gendarmería halló 16 vizcachas faenadas en Curuzú Cuatiá",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.corrienteshoy.com/galeria/fotos/2023/09/18/l_1695044549.jpg",
    desc: [
      {
        para1: "En horas de la madrugada del sábado, una de las patrullas transitaba en cercanías al acceso a la localidad y procedieron al control físico y documentológico de un vehículo los cuales se dirigían hacia Rafaela, Santa Fe. Parte de la carne secuestrada ya había sido cocida.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "En horas de la madrugada, y en momentos que la una de las patrullas transitaba en cercanías al acceso a la localidad de Curuzú Cuatiá, procedieron al control físico y documentológico de un vehículo, ocupado por dos masculinos mayores de edad, los cuales se dirigían hacia Rafaela, Santa Fe." },
      {
        quote: "Al momento de solicitar la documentación del rodado, los uniformados pudieron ver una un arma de fuego y varias bolsas plásticas, motivando la requisa del mismo. Producto de la inspección, se hallaron una carabina, 50 municiones, 16 vizcachas faenadas y sin cuero, así como tres bolsas más que contenían carne de la misma especie pero que ya había atravesado un proceso de cocción.",
      },
      {
        para2: "Se dio intervención a la Fiscalía Rural y Ambiental de Mercedes, así como a la Policía Rural y Ambiental de Curuzú Cuatiá, procediendo al secuestro del arma de fuego y los animales faenados, siendo desnaturalizados bajo acta de estilo.",
      },
      {
        para3: "",
      },
    ],
  },
]
export const ppost = [
  {
    id: 26,
    cover: "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/08/15-FOTO-150-DOLARES-EN-REDES-SOCIALES-FOTO-1-ILUSTRATIVA.jpg",
    category: "ECONOMÍA",
    title: "Crece la comercialización ilegal de divisas extranjeras en redes sociales",
    date: "19. Agosto 2024",
    desc: [
      {
        para1: "Con mínimas o casi nulas restricciones, las redes sociales se convirtieron, desde ya hace algunos años, en plataformas ideales para la comercialización de todo tipo de productos y objetos. En ese contexto, en el último tiempo se notó un incremento en la compra y venta ilegal de divisas extranjeras."
      },
      {
        para2: "Es así que cientos de usuarios utilizan las plataformas, principalmente Marketplace, de Facebook, donde casi no existen limitaciones para el comercio de productos. Allí, se ofrecen todo tipo de objetos, desde indumentaria, tecnología, electrodomésticos, artículos de bazar, así como también divisas extranjeras."
      },
      {
        para3: "Reales, euros y dólares son los billetes más requeridos por los usuarios. Sin embargo, es la moneda estadounidense la más comercializada en este tipo de sitios web. Allí, tanto compradores como vendedores buscan los billetes de distintas denominaciones, con descripciones que buscan no dejar rastros del comercio."
      }
    ],
    details: [
      {
        title: ""
      },
      {
        para1: "«Vendo azules», indican algunos comerciantes, en relación al dólar blue, la divisa que circula de forma informal, ya que no se comercializa en casas de cambio oficiales ni bancos, ni en entidades financieras o billeteras virtuales. Otros usuarios, por su parte sólo dejan en la descripción «azules y verdes», con una imagen alusiva, para que los compradores entiendan la referencia."
      },
      {
        quote: "«Caras chicas, azules y verdes», son otras de las leyendas que aparecen en las redes sociales, acompañadas de imágenes. Una de ellas, por ejemplo, deja en claro que se tratan de «dólares azules» con la «cotización del día»."
      },
      {
        para2: ""
      },
      {
        para3: ""
      }
    ]
  },
  {
    id: 27,
    cover: "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/08/apunalo-a-su-hermano-y-se-dio-a-la-fuga.jpg",
    category: "POLICIALES",
    title: "Apuñaló a su hermano y se dio a la fuga",
    date: "19. Agosto 2024",
    desc: [
      {
        para1: "Luego de una intensa búsqueda, efectivos de la Unidad Especial de Seguridad Rural y Ecológica (UESRyE) lograron detener a Miguel Ramón Gómez, quien era intensamente buscado por intentar asesinar a su propio hermano."
      },
      {
        para2: "Tras una violenta discusión, Gómez habría atacado a su familiar con un arma blanca, provocándole graves heridas. Ante la gravedad de la situación, la Policía inició un operativo para dar con el paradero del agresor, quien se había dado a la fuga."
      },
      {
        para3: "Finalmente, los efectivos divisaron a Gómez caminando a un costado de la ruta nacional 12, a la altura del Paraje Granja Melia. Fue trasladado a la Comisaría Primera de Goya, donde quedó a disposición del fiscal en turno, quien deberá resolver su situación legal."
      }
    ],
    details: [
      {
        title: ""
      },
      {
        para1: ""
      },
      {
        quote: ""
      },
      {
        para2: ""
      },
      {
        para3: ""
      }
    ]
  },
  {
    id: 28,
    cover: "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/08/policia-1.jpg",
    category: "POLICIALES",
    title: "Golpe al abigeato: desbaratan segunda banda en zona de Miriñay",
    date: "19. Agosto 2024",
    desc: [
      {
        para1: "La Unidad Especial Priar de Mercedes, en un trabajo articulado con las comisarías de Curuzú Cuatiá, Bonpland y Paso de los Libres, logró desbaratar una banda de cuatreros que operaba en zonas rurales cercanas al río Miriñay."
      },
      {
        para2: "En una serie de allanamientos realizados esta semana en la ciudad de Paso de los Libres, las fuerzas de seguridad incautaron un arsenal compuesto por armas de fuego, abundante munición, vehículos y otros elementos que, según las investigaciones, estarían vinculados a numerosos casos de abigeato en la zona."
      },
      {
        para3: "Este nuevo golpe asestado al delito rural es el resultado de un intenso trabajo de inteligencia e investigación llevado a cabo por la Priar, demostrando una vez más su compromiso en la protección del sector productivo y en la garantía de la seguridad de los productores locales."
      }
    ],
    details: [
      {
        title: ""
      },
      {
        para1: ""
      },
      {
        quote: ""
      },
      {
        para2: ""
      },
      {
        para3: ""
      }
    ]
  },
  {
    id: 29,
    cover: "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/08/05-foto-51-municipalidad.jpeg",
    category: "SOCIEDAD",
    title: "La Muni en tu Barrio llegará al Fray José de la Quintana",
    date: "19. Agosto 2024",
    desc: [
      {
        para1: "El operativo La Muni en tu Barrio, que acerca prestaciones sanitarias y servicios gratuitos a los vecinos en diferentes zonas de la ciudad, llegará este miércoles al barrio Fray José de la Quintana."
      },
      {
        para2: "Las actividades se llevarán a cabo de 8 a 12 en la intersección de las calles Cangallo y Frondizi (plaza Ex Combatientes de Malvinas). En la misma, diferentes áreas de la Municipalidad, además de organismos provinciales y nacionales, realizarán controles de peso y talla, de atención médica general, odontología, hipertensión arterial, glucemia, entre otras atenciones."
      },
      {
        para3: "Además, se llevarán a cabo otros servicios, como asesoramiento jurídico, defensa del consumidor, jubilaciones, así como la participación del programa Ñande Huerta, entre otras prestaciones."
      }
    ],
    details: [
      {
        title: ""
      },
      {
        para1: "En todos los casos, La Muni en tu Barrio está acompañado del programa Mascotas Saludables, que promueve la tenencia responsable de los animales domésticos, acercando atención veterinaria gratuita, vacunación antirrábica, desparasitaciones, y esterilizaciones (castraciones). Este último servicio debe requerirse con antelación a través del turnero web, por contar con un cupo limitado por cada operativo."
      },
      {
        quote: "Por otra parte, la Municipalidad llevará adelante por la tarde tareas de descacharrado en la zona donde previamente se realiza el operativo de La Muni en tu Barrio."
      },
      {
        para2: "Desde las 14, camiones y operarios municipales pasarán a retirar todos aquellos elementos en desuso que pueden llegar a acumular agua y actuar como reservorios para la proliferación del mosquito vector del dengue, como ser muebles y electrodomésticos viejos, llantas, cubiertas, chatarra, baldes, juguetes, recipientes, entre otros, que los vecinos deben disponer en sus veredas."
      },
      {
        para3: "Para esta oportunidad, en el barrio Lomas del Mirador, el operativo de descacharrado abarcará el cuadrante conformado por las calles Cuba, Añasco, Ex Vía y calle 95."
      }
    ]
  }
  
]
export const lifestyle = [
  {
    id: 30,
    category: "INTERIOR",
    title: "Amplio operativo para la celebración del Gauchito Gil.",
    date: "05. Enero 2024",
    comments: 0,
    cover: "https://www.diarioepoca.com/content/bucket/0/1340360w762h476c.jpg.webp",
  },
  {
    id: 31,
    category: "CORRIENTES",
    title: "Día picante en Diputados: 'Empezó mal el año', advirtió Jorge Romero",
    date: "05. Enero 2024",
    cover: "https://www.diarioepoca.com/content/bucket/6/1340346w380h179c.jpg.webp",
  },
  {
    id: 32,
    category: "CORRIENTES",
    title: "Largas filas en plena Ruta 12 para comprar una garrafa de gas a $5.000",
    date: "03. Enero 2024",
    cover: "https://www.diarioepoca.com/content/bucket/2/1340352w762h564c.jpg.webp",
  },
  {
    id: 33,
    category: "CORRIENTES",
    title: "La Provincia compró el predio de la tabacalera que cerró en Goya",
    date: "17. Septiembre 2023",
    cover: "https://www.nortecorrientes.com/content/bucket/8/369968w380h220c.jpg.webp",
  },
]
export const tpost = [
  {
    id: 34,
    title: "Radio Sudamericana",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/sudamericana-corrientes.png",
    href: "https://envivo.radiosudamericana.com/inc.popup-reproductor.php?popup=true"
  },
  {
    id: 35,
    title: "Radio Dos",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/radio-dos-corrientes.png",
    href: "https://www.radiodos.com.ar/radio.php"
  },
  {
    id: 36,
    title: "LT7",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/lt7-900-corriente.png",
    href: "https://www.lt7noticias.com/radio.php"
  },
]
export const gallery = [
  {
    cover: "../images/gallery/g1.jpg",
  },
  {
    cover: "../images/gallery/g2.jpg",
  },
  {
    cover: "../images/gallery/g3.jpg",
  },
  {
    cover: "../images/gallery/g4.jpg",
  },
  {
    cover: "../images/gallery/g5.jpg",
  },
]
export const discover = [
  {
    title: "gameñss3a22aaaaaaadasa2s",
    cover: "../images/discover/d1.jpg",
  },
  {
    title: "DEPORTES",
    cover: "../images/discover/d2.jpg",
  },
  {
    title: "humaouaaaaAaArr",
    cover: "../images/discover/d3.jpg",
  },
  {
    title: "gadgets",
    cover: "../images/discover/d4.jpg",
  },
  {
    title: "movieees",
    cover: "../images/discover/d5.jpg",
  },
  {
    title: "niten3aa2asdkik25aaaaaassadaaaao!",
    cover: "../images/discover/d6.jpg",
  },
]

